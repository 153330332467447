/*!
 * Telemedwebrtc
 * (C) MediCardia. All rights reserved.
 * 
 * Date        : May-19-2020
 * Author      : Vishnu M.I
 * Description : This file contain class defenition to setup video conference 
 *               using daily.co. And it is prepared by refering the demo apps 
 *			     available.
 *
 * Date        : 
 * Modified by :
 * Changes     :
 * 
 */


var visitModes = {
	THUMBNAIL: 1,
	FULLSCREEN: 2,
	MEDICARDIA: 3
};

var actionTypes = {
	SCREENSHARE_TOGGLE: 4,
	VIDEO_TOGGLE: 5,
	MIC_TOGGLE: 6,
	LEAVE_MEETING: 7,
	LEAVE_MEETING_THUMBNAIL: 8,
	THUMBNAIL_JOIN: 9
}

var telemedwebrtc = (function () {

	// Private member variables
	var _callFrame = null;
	var _ownerLink = null;
	var _room = null;
	var _userData = null;
	var _disableVideoVisitButton = true;

	var _action = null;
	var _visitModeSelected = null
	var _callFrameThumbnail = null;

	let participantData = {};
	let participantDataUpdatedEvent = new CustomEvent(
		"participantDataUpdated",
		{
			detail: participantData,
			bubbles: true,
			cancelable: true
		}
	);

	// Private methods.
	var _showEvent = function (e) {

		console.log('callFrame event', e)
	}

	var _setParticipantData = function (participantData) {
		var event_data = {
			action: _action,
			participantDetails: participantData
		};

		if (!arguments.callee.event) {
			arguments.callee.event = new CustomEvent(
				"participantDataUpdated")
		}
		arguments.callee.event.data = event_data;
		if (_visitModeSelected == visitModes.FULLSCREEN) {
			_getElm('call-frame-container').dispatchEvent(arguments.callee.event);
		} else {
			_callFrameThumbnail.setLocalVideo(false);
			_callFrameThumbnail.setLocalAudio(false);
			_getElm('call-frame-container-thumbnail').dispatchEvent(arguments.callee.event);
		}

	}

	var _init = function (res, mode) {

		_visitModeSelected = mode;
		_room = { url: res };
		_ownerLink = _room.url;
		if (_visitModeSelected == visitModes.FULLSCREEN) {
			_buttonDisable('join-meeting', 'leave-meeting',
				'toggle-local-cam', 'toggle-local-mic',
				'toggle-screenshare');
			_getElm('create-a-room').onclick = _createFrameAndRoom();
		} else {
			_createFrameAndRoom();
		}

		_getElm("join-meeting").onclick = function () {
			_callFrame.join({
				url: _room.url,
				customLayout: true,
				userName: `${_userData.last_name}`,
				cssFile: "assets/extensions/telecardia/style.css?t="+Date.now()
			});
		};

		_getElm("toggle-screenshare").onclick = function () {
			_toggleScreenShare();
		};


		_getElm("toggle-local-cam").onclick = function () {
			_toggleCam();
		};

		_getElm("toggle-local-mic").onclick = function () {
			_toggleMic();
		};

		_getElm("start-recording").onclick = function () {
			_toggleScreenShare()
		};

		_getElm("stop-recording").onclick = function () {
			_callFrame.stopRecording();
		};

		_getElm("leave-meeting").onclick = function () {
			_action = actionTypes.LEAVE_MEETING;
			_callFrame.leave();
			if (_callFrameThumbnail) {
				_callFrameThumbnail.leave();
			}

		};
		// } else {

		// _buttonDisable('join-meeting-thumbnail', 'leave-meeting-thumbnail',
		// 	'toggle-local-cam-thumbnail', 'toggle-local-mic-thumbnail',
		// 	'toggle-screenshare-thumbnail');

		// _createFrameAndRoom();

		// Commented as the thumbnail view on header not using. Only the floating button is using for now.
		/* _getElm("join-meeting-thumbnail").onclick = function () {
			_callFrameThumbnail.join({
				url: _room.url,
				customLayout: true,
				userName: `${_userData.last_name} ( Presentation )`,
				cssFile: "assets/extensions/telecardia/style-thumbnail.css"
			});
			_action = actionTypes.THUMBNAIL_JOIN;
		};

		_getElm("toggle-screenshare-thumbnail").onclick = function () {
			_toggleScreenShare();
		};


		_getElm("toggle-local-cam-thumbnail").onclick = function () {
			_toggleCam();
		};

		_getElm("toggle-local-mic-thumbnail").onclick = function () {
			_toggleMic();
		};

		_getElm("start-recording-thumbnail").onclick = function () {
			_toggleScreenShare()
		};

		_getElm("stop-recording-thumbnail").onclick = function () {
			_callFrameThumbnail.stopRecording();
		};

		_getElm("leave-meeting-thumbnail").onclick = function () {
			if(_callFrameThumbnail !== null){
				_callFrameThumbnail.leave();
			}
		};*/
	}

	var _createRoom = async function () {

		//
		// create a short-lived demo room and a meeting token that gives
		// owner privileges and allows recording. if you just want to
		// hard-code a meeting link for testing you could do something like
		// this:
		//
		//   room = { url: 'https://your-domain.daily.co/hello' }
		//   ownerLink = room.url;
		//
		// room = await createMtgRoom();
		// ownerLink = await createMtgLinkWithToken(
		//   room, { 
		//     is_owner: true,
		//     enable_recording: 'local'
		// });

		_updateRoomInfoDisplay();
		// setInterval(updateNetworkInfoDisplay, 5000);
	}

	var _createFrame = async function () {
		//
		// ask the daily-js library to create an iframe inside the
		// 'call-frame-container' div
		//
		let customLayout = false;

		cssFile = 'basics.css';
		if (_visitModeSelected == visitModes.FULLSCREEN) {

			_callFrame = window.DailyIframe.createFrame(
				_getElm('call-frame-container'),
				{ customLayout, cssFile }
			);

			_callFrame.on('loading', (e) => {
				console.log('loading error ', e);
				_showEvent(e);
				_buttonDisable('join-meeting');
				/* setTimeout(() => {
					_callFrame.loadCss({
						cssText: `
						.bgnVfq{
							height: 16px !important;
							display: none !important;
						}
						.kMhNbH{
							height: 24px !important;
							background-color: rgba(255,255,255,0.5);
						}
						.NLxcI, .kajOpL{
							bottom: 30px !important;
						}
						.flex-layout.sc-ZUflv.fieQlm{
							display: block;
						}
					  `,
					  });
				}, 200); */
				
			}
			);

			_callFrame.on('loaded', _showEvent)
				.on('started-camera', _showEvent)
				.on('camera-error', _showEvent)
				.on('joining-meeting', _showEvent)
				.on('recording-stats', _showEvent)
				.on('recording-error', _showEvent)
				.on('app-message', _showEvent)
				.on('input-event', _showEvent)
				.on('error', _showEvent);

			_callFrame.on('joined-meeting', (e) => {
				console.log('joined-meeting error ', e);
				_showEvent(e);
				_buttonEnable('leave-meeting', 'toggle-local-cam',
					'toggle-local-mic', 'start-recording',
					'toggle-screenshare'
				);
				participantData = e.participants.local;
				_setParticipantData(participantData);
			}
			);

			_callFrame.on('recording-started', (e) => {
				_showEvent(e);
				_buttonDisable('start-recording');
				_buttonEnable('stop-recording');
			}
			);

			_callFrame.on('recording-stopped', (e) => {
				_showEvent(e);
				_buttonEnable('start-recording');
				_buttonDisable('stop-recording');
			}
			);

			_callFrame.on('participant-joined', _updateParticipantInfoDisplay)
				.on('participant-updated', _updateParticipantInfoDisplay)
				.on('participant-left', _updateParticipantInfoDisplay);

		} else {
			_callFrameThumbnail = window.DailyIframe.createFrame(
				_getElm('call-frame-container-thumbnail'),
				{ customLayout, cssFile }
			);


			_callFrameThumbnail.on('loading', (e) => {
				console.log('loading error ', e);
				_showEvent(e);
			}
			);



			_callFrameThumbnail.on('loaded', _showEvent)
				.on('started-camera', _showEvent)
				.on('camera-error', _showEvent)
				.on('joining-meeting', _showEvent)
				.on('recording-stats', _showEvent)
				.on('recording-error', _showEvent)
				.on('app-message', _showEvent)
				.on('input-event', _showEvent)
				.on('error', _showEvent);



			_callFrameThumbnail.on('joined-meeting-thumbnail', (e) => {
				console.log('joined-meeting error ', e);
				_showEvent(e);
				_buttonEnable('toggle-local-cam-thumbnail', 'leave-meeting-thumbnail',
					'toggle-local-mic-thumbnail',
					'toggle-screenshare-thumbnail'
				);
			}
			);

			_callFrameThumbnail.on('participant-joined', _updateParticipantInfoDisplay)
				.on('participant-updated', _updateParticipantInfoDisplay)
				.on('participant-left', _updateParticipantInfoDisplay);
		}

		if (_callFrameThumbnail) {
			_callFrameThumbnail.on('left-meeting', (e) => {
				_showEvent(e);
				_buttonDisable('leave-meeting-thumbnail',
					'toggle-local-cam-thumbnail', 'toggle-local-mic-thumbnail',
					'toggle-screenshare-thumbnail');
				_buttonEnable('join-meeting-thumbnail');
			}
			);
		}

		if (_callFrame) {
			_callFrame.on('left-meeting', (e) => {
				_showEvent(e);
				_buttonDisable('leave-meeting',
					'toggle-local-cam', 'toggle-local-mic',
					'toggle-screenshare');
				_buttonEnable('join-meeting');
				/* setTimeout(() => {
					_callFrame.loadCss({
						cssText: `
						.bgnVfq{
							height: 16px !important;
							display: none !important;
						}
						.kMhNbH{
							height: 24px !important;
							background-color: rgba(255,255,255,0.5);
							display: flex !important;
						}
						.NLxcI, .kajOpL{
							bottom: 30px !important;
						}
						.flex-layout.sc-ZUflv.fieQlm{
							display: block;
						}
						.dplDjL{
							display:flex;
						}
						.sc-lhLRcH{
							left:14px;
						}
					  `,
					  });
				}, 200); */
			}
			);
		}
	}

	var _createFrameAndRoom = async function () {
		// _getElm('create-a-room').style.display = 'none';
		if (_room.url) {
			_buttonDisable('create-a-room');
			await _createRoom();
			await _createFrame();
			if (_disableVideoVisitButton === false) {
				_buttonEnable('join-meeting');
			} else {
				_buttonDisable('join-meeting', 'leave-meeting',
					'toggle-local-cam', 'toggle-local-mic',
					'toggle-screenshare');
			}
		}
	}

	var _updateRoomInfoDisplay = function () {
	}

	var _updateParticipantInfoDisplay = function (e) {

		_showEvent(e);
		// update local screenshare button state
		switch (e.action) {
			case "participant-updated":
				if (e.participant.local) {
					participantData = e.participant;
				}
				if (_visitModeSelected == visitModes.FULLSCREEN) {

					_buttonEnable('leave-meeting', 'toggle-local-cam',
						'toggle-local-mic',
						'toggle-screenshare'
					);
				} else {
					_buttonEnable('leave-meeting-thumbnail', 'toggle-local-cam-thumbnail',
						'toggle-local-mic-thumbnail',
						'toggle-screenshare-thumbnail'
					);
				}
				if (_callFrameThumbnail && Object.keys(_callFrameThumbnail.participants()).length &&
					(_callFrameThumbnail.participants().local.audio
						|| _callFrameThumbnail.participants().local.video)) {
					_callFrameThumbnail.setLocalVideo(false);
					_callFrameThumbnail.setLocalAudio(false);
				}
				break;

			case "joined-meeting":
				if (e.participants.local.local) {
					participantData = e.participants.local;
				}
				if (_visitModeSelected == visitModes.FULLSCREEN) {
					_buttonEnable('leave-meeting', 'toggle-local-cam',
						'toggle-local-mic',
						'toggle-screenshare'
					);
				}
				break;
		}
		_setParticipantData(participantData);

	}

	var _updateNetworkInfoDisplay = async function () {

		let infoEl = _getElm('network-info'),
			statsInfo = await _callFrame.getNetworkStats();
		infoEl.innerHTML = `
							<div>
							  <div>
								Video send:
								${Math.floor(statsInfo.stats.latest.videoSendBitsPerSecond / 1000)} kb/s
							  </div>
							  <div>
								Video recv:
								${Math.floor(statsInfo.stats.latest.videoRecvBitsPerSecond / 1000)} kb/s
							  <div>
								Worst send packet loss:
								${Math.floor(statsInfo.stats.worstVideoSendPacketLoss * 100)}%</div>
							  <div>Worst recv packet loss:
								${Math.floor(statsInfo.stats.worstVideoRecvPacketLoss * 100)}%</div>
							</div>
		`;
	}

	var _toggleCam = function () {
		_action = actionTypes.VIDEO_TOGGLE;
		if (_visitModeSelected == visitModes.FULLSCREEN) {
			_callFrame.setLocalVideo(!_callFrame.participants().local.video);
		} else {
			_callFrameThumbnail.setLocalVideo(false);
		}
	}

	var _toggleMic = function () {
		_action = actionTypes.MIC_TOGGLE;
		if (_visitModeSelected == visitModes.FULLSCREEN) {
			_callFrame.setLocalAudio(!_callFrame.participants().local.audio);
		} else {
			_callFrameThumbnail.setLocalAudio(false);
		}
	}

	var _toggleScreenShare = function () {
		if (_userData.isPhysician) {
			_action = actionTypes.SCREENSHARE_TOGGLE;
			if (_callFrame.participants().local.screen) {
				_callFrame.stopScreenShare();
				
				/* setTimeout(() => {
					_callFrame.loadCss({
						cssText: `
						.bgnVfq{
							height: 16px !important;
							display: none !important;
						}
						.kMhNbH{
							height: 24px !important;
							background-color: rgba(255,255,255,0.5);
						}
						.NLxcI, .kajOpL{
							bottom: 30px !important;
						}
						.flex-layout.sc-ZUflv.fieQlm{
							display: block;
						}
						.dplDjL{
							display:flex;
						}
						.sc-lhLRcH{
							left:14px;
						}
					  `,
					  });
				}, 200); */
				if (_callFrameThumbnail) {
					_callFrameThumbnail.leave();
				}
			} else {
				_callFrame.startScreenShare();
				/* setTimeout(() => {
					_callFrame.loadCss({
						cssText: `
						.bgnVfq{
							height: 16px !important;
							display: none !important;
						}
						.kMhNbH{
							height: 24px !important;
							background-color: rgba(255,255,255,0.5);
						}
						.NLxcI, .kajOpL{
							bottom: 0px !important;
						}
						.flex-layout.sc-ZUflv.fieQlm, .dplDjL{
							display: none;
						}
						.iPEbfD.flex-layout{
							display: none;
						}
						.sc-lhLRcH{
							left:0;
						}
					  `,
					  });
				  }, 200); */
				
			}
		}

	}


	// Utility methods
	var _getElm = function (id) {

		return document.getElementById(id);

	}

	var _buttonEnable = function (...args) {
		args.forEach((id) => {
			let el = _getElm(id);
			if (el) {
				el.disabled = false;
				el.classList.remove('disabled');
			}
		}
		);
	}

	var _buttonDisable = function (...args) {

		args.forEach((id) => {
			let el = _getElm(id);

			if (el) {
				el.disabled = true;
				el.classList.add('disabled');
			}
		}
		);
	}

	var _setViewMode = function (value) {
		_visitModeSelected = value;
	}

	var _setUserData = function (value) {
		_userData = value;
	}

	var _disableVideoVisit = function (value) {
		_disableVideoVisitButton = value;
	}


	return {
		// Public Methods
		init: _init,
		setViewMode: _setViewMode,
		setUserData: _setUserData,
		createFrameAndRoom: _createFrameAndRoom,
		showEvent: _showEvent,
		toggleCam: _toggleCam,
		toggleMic: _toggleMic,
		buttonEnable: _buttonEnable,
		buttonDisable: _buttonDisable,
		disableVideoVisit: _disableVideoVisit
	}
}());